// import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import VerticalTable1 from "./pole_tables/VerticalTable1";
// import VerticalTable2 from "./pole_tables/VerticalTable2";
// import VerticalTable3 from "./pole_tables/VerticalTable3";
// import "rc-switch/assets/index.css";
// import image1 from "./pics/abc1.png";
// import image2 from "./pics/abc2.png";
// import { Button } from "react-bootstrap";
// import { string } from "yup";
// import { addComment, updateRecord } from "../Services/apiCalling";
// import { Modal } from "bootstrap";
// // import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

// const ApplicationModal = ({ clickedRecord }) => {
//   const token = localStorage.getItem("LogIn_Token", clickedRecord);
//   // //console.log("clickedRecordclickedRecord", clickedRecord);
//   const navigate = useNavigate();
//   // //console.log("fghjkl;",path.split("\\")[-1])
//   const [comment, setComment] = useState("");
//   // const [okCases, setOkCases] = useState([]);
//   const [summary, setSummary] = useState({});
//   const [assetHealth, setAssetHealth] = useState();
//   const [issue, setIssue] = useState({
//     tilt: "NO",
//     pcc_damage: "NO",
//     iron_damage: "NO",
//     pcc_crack: "NO",
//     vegetation: "NO",
//     rust: "NO",
//   });

//   const [other, setOther] = useState({
//     pcc_pole: "NO",
//     pcc_pole_head: "NO",
//     iron_pole: "YES",
//     iron_pole_jumper: "NO",
//   });

//   useEffect(() => {
//     if (clickedRecord && clickedRecord.length > 0) {
//       const record = clickedRecord[0];
//       // //console.log("recordrecord", record);
//       setAssetHealth(record.health);

//       // Prepare the new values for `issue` and `other`
//       const newIssue = {
//         tilt: record?.tilt == true ? "YES" : "NO",
//         pcc_damage: record?.pcc_damage == true ? "YES" : "NO",
//         iron_damage: record?.iron_damage == true ? "YES" : "NO",
//         pcc_crack: record?.pcc_crack == true ? "YES" : "NO",
//         vegetation: record?.vegetation == true ? "YES" : "NO",
//         rust: record?.rust == true ? "YES" : "NO",
//       };

//       const newOther = {
//         pcc_pole: record?.pcc_pole == true ? "YES" : "NO",
//         pcc_pole_head: record?.pcc_pole_head == true ? "YES" : "NO",
//         iron_pole: record?.iron_pole == true ? "YES" : "NO",
//         iron_pole_jumper: record?.iron_pole_jumper == true ? "YES" : "NO",
//         latitude: record?.lat,
//         longitude: record?.long,
//       };

//       // Only update state if `newIssue` and `newOther` are different from the current state
//       setIssue((prevIssue) => {
//         if (JSON.stringify(prevIssue) !== JSON.stringify(newIssue)) {
//           return newIssue;
//         }
//         return prevIssue;
//       });

//       setOther((prevOther) => {
//         if (JSON.stringify(prevOther) !== JSON.stringify(newOther)) {
//           return newOther;
//         }
//         return prevOther;
//       });
//     }
//   }, [clickedRecord]);

//   const removeBackdrop = () => {
//     const backdrop = document.querySelector(".modal-backdrop");
//     if (backdrop) {
//       backdrop.remove();
//     }
//   };

//   const updatedDetails = async (data, id, report) => {
//     try {
//       await adComment(id, comment);

//       const fetchedData = await updateRecord(data, id, report);
//       // const addComment = await addComment(id, comment);
//       // //console.log("Fetched Data from updateRecord:", fetchedData);
//       // //console.log("Fetched Data after comment api hit:", addComment);

//       if (report == "crew") {
//         navigate("/crew-report");
//       } else if (report == "noAction") {
//         navigate("/no-action-required-report");
//       } else {
//         navigate("/save-later-report");
//       }
//       closeModal();
//     } catch (error) {
//       alert("Error during API calls:", error);
//       // notification.error({ message: "Error fetching zone data" });
//     } finally {
//       // setIsLoading(false);
//     }
//   };

//   const adComment = async (id, comment) => {
//     // //console.log("id", id, " comment", comment);
//     try {
//       const commentResponse = await addComment(id, comment);
//       // //console.log("Comment added successfully:", commentResponse);
//     } catch (error) {
//       // console.error("Error adding comment:", error);
//     }
//   };

//   const handleAutoApprove = () => {
//     updatedDetails(summary, clickedRecord[0]?.id, "noAction");
//     closeModal();
//   };
//   const handleSaveLater = () => {
//     // //console.log("handleSaveLaterclickedRecord", clickedRecord);
//     updatedDetails(summary, clickedRecord[0]?.id, "saveLater");
//   };
//   const handleCrew = () => {
//     // //console.log("clickedRecordclickedRecordclickedRecord", clickedRecord);
//     // //console.log("issue", issue);
//     // //console.log("other", other);
//     updatedDetails(summary, clickedRecord[0]?.id, "crew");
//   };
//   const modalRef = useRef(null);
//   const closeModal = () => {
//     if (modalRef.current) {
//       // Hides the modal using Bootstrap's modal method
//       modalRef.current.classList.remove("show");
//       modalRef.current.style.display = "none";
//     }
//   };

//   const handleUpdateChanges = (issue, other) => {
//     setSummary({
//       ...issue,
//       ...other,
//     });
//     // setComment(comment);
//     // //console.log("summary", summary);
//   };

//   useEffect(() => {
//     handleUpdateChanges(issue, other);
//     // addComment(clickedRecord[0]?.id, comment);
//     // await addComment(clickedRecord[0]?.id,comment);
//   }, [issue, other]);
//   // //console.log("clickedRecordclickedRecord", clickedRecord);
//   const issue_array = Object.values(issue);
//   const hasIssues = issue_array?.includes("YES");

//   return (
//     <div
//       id="edit_leave"
//       className="modal custom-modal fade"
//       role="dialog"
//       ref={modalRef}

//       // data-bs-backdrop="static"
//     >
//       <div
//         className="modal-dialog modal-dialog-centered modal-lg"
//         role="document"
//         style={{ maxWidth: "1200px" }}
//       >
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title">Predictive Maintenance</h5>
//             <button
//               type="button"
//               className="btn-close"
//               data-bs-dismiss="modal"
//               aria-label="Close"
//               onClick={closeModal}
//             >
//               <span aria-hidden="true">×</span>
//             </button>
//           </div>
//           <div className="modal-body">
//             <div className="flex row justify-content-around">
//               <div className="col-5" style={{ border: "1px solid red" }}>
//                 <h5 className="text-center" style={{ color: "#FF902F" }}>
//                   Original Image
//                 </h5>
//                 <img
//                   src={`${clickedRecord[0]?.Input_img_URL}`}
//                   alt="Placeholder"
//                   // src={`https://predective-maintenance-1729788871050.s3.ap-south-1.amazonaws.com/input_final_1/${clickedRecord[0]?.assetName}`}
//                 />
//               </div>
//               <div className="col-5" style={{ border: "1px solid red" }}>
//                 <h5 className="text-center" style={{ color: "#FF902F" }}>
//                   After Detection
//                 </h5>
//                 <img
//                   src={`${clickedRecord[0]?.Output_img_URL}`}
//                   alt="Placeholder"
//                   // src={`https://predective-maintenance-1729788871050.s3.ap-south-1.amazonaws.com/output_final/${clickedRecord[0]?.assetName}`}
//                 />
//               </div>
//             </div>
//             <div
//               className="text-center mt-2"
//               style={{ backgroundColor: "khaki" }}
//             >
//               <h5>Asset Features</h5>
//             </div>
//             <div className="flex row justify-content-around mt-2">
//               <div className="col-3" style={{ border: "1px solid black" }}>
//                 <VerticalTable1 issue={issue} setIssue={setIssue} />
//               </div>
//               <div className="col-3" style={{ border: "1px solid black" }}>
//                 <VerticalTable2 other={other} setOther={setOther} />
//               </div>
//               <div className="col-5" style={{ border: "1px solid black" }}>
//                 <VerticalTable3 summery={[issue, other]} />
//               </div>
//             </div>
//             <div className="text-end">
//               <input
//                 className="mt-3 w-100"
//                 value={comment}
//                 onChange={(e) => setComment(e.target.value)}
//                 placeholder="Write your comment..."
//               />
//             </div>
//             <div className="flex row justify-content-around mt-3">
//               <div className="col-4 text-center">
//                 <button
//                   onClick={() => handleCrew()}
//                   type="button"
//                   className="btn btn-outline-danger"
//                   disabled={!hasIssues}
//                 >
//                   Action Required
//                 </button>
//               </div>
//               <div className="col-4 text-center">
//                 <button
//                   type="button"
//                   className="btn btn-outline-warning"
//                   disabled={!hasIssues}
//                   onClick={() => handleSaveLater()}
//                 >
//                   Save For Later
//                 </button>
//               </div>
//               <div className="col-4 text-center">
//                 {/* <Button  className="btn btn-outline-success"

//                  onDoubleClick={()=>{
//                    //console.log("action require")
//                 }}> */}
//                 <button
//                   type="button"
//                   onClick={() => handleAutoApprove()}
//                   className="btn btn-primary me-2"
//                 >
//                   No Action Required
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ApplicationModal;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import VerticalTable1 from "./pole_tables/VerticalTable1";
import VerticalTable2 from "./pole_tables/VerticalTable2";
import VerticalTable3 from "./pole_tables/VerticalTable3";
import "rc-switch/assets/index.css";
import image1 from "./pics/abc1.png";
import image2 from "./pics/abc2.png";
import { Button } from "react-bootstrap";
import { addComment, updateRecord } from "../Services/apiCalling";
import { Modal } from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const ApplicationModal = ({ clickedRecord }) => {
  const token = localStorage.getItem("LogIn_Token", clickedRecord);
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [summary, setSummary] = useState({});
  const [assetHealth, setAssetHealth] = useState();
  const [issue, setIssue] = useState({
    tilt: "NO",
    pcc_damage: "NO",
    iron_damage: "NO",
    pcc_crack: "NO",
    vegetation: "NO",
    rust: "NO",
  });

  const [other, setOther] = useState({
    pcc_pole: "NO",
    pcc_pole_head: "NO",
    iron_pole: "YES",
    iron_pole_jumper: "NO",
  });

  const modalRef = useRef(null);

  useEffect(() => {
    if (clickedRecord && clickedRecord.length > 0) {
      const record = clickedRecord[0];
      setAssetHealth(record.health);

      const newIssue = {
        tilt: record?.tilt === true ? "YES" : "NO",
        pcc_damage: record?.pcc_damage === true ? "YES" : "NO",
        iron_damage: record?.iron_damage === true ? "YES" : "NO",
        pcc_crack: record?.pcc_crack === true ? "YES" : "NO",
        vegetation: record?.vegetation === true ? "YES" : "NO",
        rust: record?.rust === true ? "YES" : "NO",
      };

      const newOther = {
        pcc_pole: record?.pcc_pole === true ? "YES" : "NO",
        pcc_pole_head: record?.pcc_pole_head === true ? "YES" : "NO",
        iron_pole: record?.iron_pole === true ? "YES" : "NO",
        iron_pole_jumper: record?.iron_pole_jumper === true ? "YES" : "NO",
        latitude: record?.lat,
        longitude: record?.long,
      };

      setIssue((prevIssue) =>
        JSON.stringify(prevIssue) !== JSON.stringify(newIssue)
          ? newIssue
          : prevIssue
      );
      setOther((prevOther) =>
        JSON.stringify(prevOther) !== JSON.stringify(newOther)
          ? newOther
          : prevOther
      );
    }
  }, [clickedRecord]);

  const removeBackdrop = () => {
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    }
  };

  const updatedDetails = async (data, id, report) => {
    try {
      await addComment(id, comment);

      const fetchedData = await updateRecord(data, id, report);

      if (report === "crew") {
        navigate("/crew-report");
      } else if (report === "noAction") {
        navigate("/no-action-required-report");
      } else {
        navigate("/save-later-report");
      }
      closeModal();
    } catch (error) {
      alert("Error during API calls:", error);
    }
  };

  const handleAutoApprove = () => {
    updatedDetails(summary, clickedRecord[0]?.id, "noAction");
    closeModal();
  };

  const handleSaveLater = () => {
    updatedDetails(summary, clickedRecord[0]?.id, "saveLater");
    closeModal();
  };

  const handleCrew = () => {
    updatedDetails(summary, clickedRecord[0]?.id, "crew");
    closeModal();
  };

  const closeModal = () => {
    if (modalRef.current) {
      const modalInstance = new Modal(modalRef.current);
      modalInstance.hide();
      removeBackdrop();
    }
  };

  const handleUpdateChanges = (issue, other) => {
    setSummary({
      ...issue,
      ...other,
    });
  };

  useEffect(() => {
    handleUpdateChanges(issue, other);
  }, [issue, other]);

  const issue_array = Object.values(issue);
  const hasIssues = issue_array?.includes("YES");

  useEffect(() => {
    return () => {
      removeBackdrop(); // Clean up backdrop on unmount
    };
  }, []);

  return (
    <div
      id="edit_leave"
      className="modal custom-modal fade"
      role="dialog"
      ref={modalRef}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
        style={{ maxWidth: "1200px" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Predictive Maintenance</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="flex row justify-content-around">
              <div className="col-5" style={{ border: "1px solid red" }}>
                <h5 className="text-center" style={{ color: "#FF902F" }}>
                  Original Image
                </h5>
                <img
                  src={`${clickedRecord[0]?.Input_img_URL}`}
                  alt="Placeholder"
                />
              </div>
              <div className="col-5" style={{ border: "1px solid red" }}>
                <h5 className="text-center" style={{ color: "#FF902F" }}>
                  After Detection
                </h5>
                <img
                  src={`${clickedRecord[0]?.Output_img_URL}`}
                  alt="Placeholder"
                />
              </div>
            </div>
            <div
              className="text-center mt-2"
              style={{ backgroundColor: "khaki" }}
            >
              <h5>Asset Features</h5>
            </div>
            <div className="flex row justify-content-around mt-2">
              <div className="col-3" style={{ border: "1px solid black" }}>
                <VerticalTable1 issue={issue} setIssue={setIssue} />
              </div>
              <div className="col-3" style={{ border: "1px solid black" }}>
                <VerticalTable2 other={other} setOther={setOther} />
              </div>
              <div className="col-5" style={{ border: "1px solid black" }}>
                <VerticalTable3 summery={[issue, other]} />
              </div>
            </div>
            <div className="text-end">
              <input
                className="mt-3 w-100"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write your comment..."
              />
            </div>
            <div className="flex row justify-content-around mt-3">
              <div className="col-4 text-center">
                <button
                  onClick={() => handleCrew()}
                  type="button"
                  className="btn btn-outline-danger"
                  disabled={!hasIssues}
                >
                  Action Required
                </button>
              </div>
              <div className="col-4 text-center">
                <button
                  type="button"
                  className="btn btn-outline-warning"
                  disabled={!hasIssues}
                  onClick={() => handleSaveLater()}
                >
                  Save For Later
                </button>
              </div>
              <div className="col-4 text-center">
                <button
                  type="button"
                  onClick={() => handleAutoApprove()}
                  className="btn btn-primary me-2"
                >
                  No Action Required
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationModal;
