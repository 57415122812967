import React, { useEffect, useState } from "react";
import PersonProfile from "./component/personProfile";
import PersonCalender from "./component/personCalender";
import {
  getEmergencyContact,
  getSpecificProfile,
} from "../Services/ApiCalling";
import { useLocation } from "react-router-dom";

function PersonProfileView() {
  const location = useLocation();
  //console.log("location",location)
  let EMP_ID = location.state;
  const [profileData, setProfileData] = useState();
  const [calendarData, setCalenderData] = useState();
  const [emgData, setEmgData] = useState();

  const token = localStorage.getItem("LogIn_Token");
  const getProfileData = async (token, EMP_ID) => {
    const res = await getSpecificProfile(token, EMP_ID);
    setProfileData(res.data);
    setCalenderData(res.empRequest);
    setEmgData(res.emrgencyProfile);
    // //console.log('respppkkkkk', res.emrgencyProfile);
    //console.log("res.data",res.data);
    //console.log("resresres",res);
  };
  useEffect(() => {
    getProfileData(token, EMP_ID);
  }, []);

  return (
    <div>
      {profileData && (
        <PersonProfile profileData={profileData} emgData={emgData || null} />
      )}
      {calendarData && <PersonCalender calendarData={calendarData} />}
    </div>
  );
}

export default PersonProfileView;
