import React from "react";
import PendingReport from "./PendingReport";

const ReportsView = () => {
  return (
    <>
      <PendingReport />
    </>
  );
};

export default ReportsView;
