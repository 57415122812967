import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import PersonProfileView from "../../specificProfile";

const AllEmployee = ({
  employeeData,
  setFilter_id,
  setFilter_name,
  setFilter_desg,
  filter_id,
  filter_name,
  filter_desg,
}) => {
  const navigate = useNavigate();
  // //console.log("employeeData123",employeeData.length)

  // useEffect(()=>{
  // //console.log('eeee', employeeData)
  // //console.log('setFilter_id', filter_id)

  // },[])
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
            setFilter_id={setFilter_id}
          />
          {/* <EmployeeListFilter setFilter_id={setFilter_id} setFilter_name={setFilter_name} setFilter_desg={setFilter_desg}
           filter_id={filter_id} filter_name={filter_name} filter_desg={filter_desg} /> */}

          <div className="row">
            {
              //  employeeData?.length >0 ?
              //  (
              employeeData.map((employee) => (
                <div
                  className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                  key={employee.id}
                >
                  <div className="profile-widget">
                    <div className="profile-img">
                      <span className="avatar">
                        <img
                          src={employee.EMP_PROFILE_PIC}
                          alt=""
                          onClick={() =>
                            navigate("/personProfile", {
                              state: employee.EMP_ID,
                            })
                          }
                          //  onClick={()=>(PersonProfileView(employee.EMP_ID))}
                        />
                      </span>
                    </div>
                    {/* <div className="dropdown profile-action">
                    <Link
                      to="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_employee"
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                      >
                        <i className="fa-regular fa-trash-can m-r-5" /> Delete
                      </Link>
                    </div>
                      </div> */}
                    <h4
                      className="user-name m-t-10 mb-0 text-ellipsis"
                      onClick={() =>
                        navigate("/personProfile", { state: employee.EMP_ID })
                      }
                      style={{ cursor: "pointer", backgroundColor: "#F7F7F7" }}
                    >
                      {employee.EMP_NAME} {/*yash change  lineNo:-71*/}
                    </h4>
                    <div className="small text-muted">
                      {employee.EMP_CURR_DESG}
                    </div>
                  </div>
                </div>
              ))
              // ) :
              // (
              //   <h3>No Data Found</h3>
              // )
            }
          </div>
        </div>
      </div>

      {/* <AllEmployeeAddPopup /> */}
      {/* Delete Modal */}
      {/* <DeleteModal Name="Delete Employee" /> */}
      {/* Delete Modal */}
    </div>
  );
};

export default AllEmployee;
