// export default Register;
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { sigUp } from "../../Services/AuthServices";
import { ToastContainer, toast } from "react-toastify";

const emailrgx = /^[a-zA-Z0-9._%+-]+@thesaicomputers\.com$/;

const validationSchema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Email should end with @thesaicomputers.com")
    .required("Email is required")
    .trim(),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required")
    .trim(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const Register = () => {
  const [passwordEye, setPasswordEye] = useState(true);
  const [repeatPasswordEye, setRepeatPasswordEye] = useState(true);
  const navigate = useNavigate();

  const showSignUpError = (message) => {
    toast.error(message, {
      position: "top-right",
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      navigate("/sign-in");

      // const res = await sigUp(values);
      // if (res.Success) {
      // navigate("/sign-in");
      // } else {
      //   showSignUpError(res.message);
      // }
    } catch (error) {
      showSignUpError("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting }) => (
          // //console.log("values", values),
          <div className="account-page">
            <div className="main-wrapper">
              <div className="account-content">
                <div className="container">
                  <div className="account-box">
                    <div className="account-wrapper">
                      <h3 className="account-title">Register</h3>
                      <p className="account-subtitle">
                        Access to our dashboard
                      </p>
                      <Form>
                        <div className="input-block mb-3">
                          <label className="col-form-label">Email</label>
                          <Field
                            type="text"
                            name="email"
                            className="form-control"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="input-block mb-3">
                          <label className="col-form-label">Password</label>
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <Field
                              type={passwordEye ? "password" : "text"}
                              name="password"
                              className="form-control"
                              autoComplete="off"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() => setPasswordEye(!passwordEye)}
                              className={`fa toggle-password ${
                                passwordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Confirm Password
                          </label>
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <Field
                              type={repeatPasswordEye ? "password" : "text"}
                              name="confirmPassword"
                              className="form-control"
                              autoComplete="off"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() =>
                                setRepeatPasswordEye(!repeatPasswordEye)
                              }
                              className={`fa toggle-password ${
                                repeatPasswordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="input-block text-center">
                          <button
                            type="submit"
                            className="btn btn-primary account-btn"
                            disabled={isSubmitting}
                          >
                            Register
                          </button>
                        </div>
                      </Form>

                      <div className="account-footer">
                        <p>
                          Already have an account? <Link to="/">Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default Register;
