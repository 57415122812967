import React, { useState, useEffect } from "react";
// import Index from "./components/UserProfile";
import Profile from "./components/UserProfile";
import {
  getEmergencyContact,
  getUserProfileData,
} from "../Services/ApiCalling";

function ProfileView() {
  const [userData, setuserData] = useState(null);
  const [emgData, setEmgData] = useState();

  const token = localStorage.getItem("LogIn_Token");

  const fetchProdileData = async () => {
    const getProfile = await getUserProfileData(token);
    setuserData(getProfile.data);
  };

  useEffect(() => {
    fetchProdileData();
  }, []);

  const getEmergencyData = async (token) => {
    const res = await getEmergencyContact(token);
    setEmgData(res.data);
  };
  useEffect(() => {
    getEmergencyData(token);
  }, []);

  return (
    <>
      {userData && (
        <Profile
          userData={userData}
          emgData={emgData}
          getEmergencyData={getEmergencyData}
        />
      )}
    </>
  );
}

export default ProfileView;
