import { useEffect, useState } from "react";
import EmployeeList from "./component/allEmp";
import { getAllEmployeeData } from "../Services/ApiCalling";

const AllEmployeeView = () => {
  const [employeeData, setEmployeeData] = useState();

  const token = localStorage.getItem("LogIn_Token");

  const fetchEmployee = async (token, filter_id, filter_name, filter_desg) => {
    const resp = await getAllEmployeeData(
      token,
      filter_id,
      filter_name,
      filter_desg
    );
    //  //console.log("respresp",resp)
    setEmployeeData(resp.data);
  };
  useEffect(() => {
    fetchEmployee(token);
  }, []);
  return (
    <>
      {employeeData && employeeData.length > 0 && (
        <EmployeeList employeeData={employeeData} />
      )}
    </>
  );
};

export default AllEmployeeView;
