// import Item from 'antd/es/list/Item';
// import React, { useEffect, useState } from 'react';
import { Form } from "react-bootstrap";

const VerticalTable1 = ({ issue, setIssue }) => {
  const handleToggle = (key) => {
    setIssue((prev) => ({
      ...prev,
      [key]: prev[key] === "YES" ? "NO" : "YES",
    }));
  };

  return (
    <table>
      <th>
        <td>Irregularities found</td>
      </th>
      <tbody>
        {Object.keys(issue).map((key) => {
          return (
            <tr key={key}>
              <td style={{ paddingRight: "10px" }}>{key}</td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={issue[key] === "YES" ? "YES" : "NO"}
                    checked={issue[key] === "YES" ? true : false}
                    onChange={() => handleToggle(key)}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default VerticalTable1;
