const VerticalTable3 = ({ summery }) => {
  // //console.log(summery);
  return (
    <table>
      <th>
        <td>Summary</td>
      </th>
      <tbody>
        <tr>
          <td style={{ paddingTop: "10px" }}>
            {Object.entries(summery[0]).map(([key, value]) => (
              <tr key={key}>
                <td>{key}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>{value}</td>
              </tr>
            ))}
          </td>
          &nbsp;&nbsp;&nbsp;
          <td style={{ paddingLeft: "20px" }}>
            {Object.entries(summery[1]).map(([key, value]) => (
              <tr key={key}>
                <td>{key}&nbsp;&nbsp;</td>
                <td>{value}</td>
              </tr>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default VerticalTable3;
