import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row, Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import LeaveFilter from "../../../../components/LeaveFilter";
import LeaveFilter from "./LeaveFilter";
import { render } from "@testing-library/react";
import { AlignCenter } from "react-feather";
import { getCrewTableData } from "../Services/apiCalling";
import { getFilter } from "../Services/api";

const CrewReport = () => {
  const [fieldInputs, setFieldInputs] = useState(true);
  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [tableDataa, setTableDataa] = useState({});
  const [totalRec, setTotalRec] = useState(0);
  const location = useLocation();
  const fetchedOkCases = location.state;

  const date = new Date(); //yash
  let curr_date = date.toLocaleDateString(); //yash
  let first_date = new Date(date.getFullYear(), date.getMonth() - 1, 1); //yash

  ///convert date format into year-month-day start
  const optionsFrom = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  let localeDateFrom = new Date(first_date).toLocaleDateString(
    "en-GB",
    optionsFrom
  ); // format: dd/mm/yyyy
  const [day1, month1, year1] = localeDateFrom.split("/");
  const f = `${year1}-${month1}-${day1}`;

  const optionsTo = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  let localeDateTo = new Date(curr_date).toLocaleDateString("en-GB", optionsTo); // format: dd/mm/yyyy
  const [day2, month2, year2] = localeDateTo.split("/");
  const t = `${year2}-${month2}-${day2}`;
  ///convert date format into year-month-day end

  const [from, setFrom] = useState(f);
  const [to, setTo] = useState(t);
  const [empArea, setEmpArea] = useState("");

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        document.exitFullscreen && document.exitFullscreen();
        setFullScreen(false);
      }
    };

    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener("click", handleClick);

    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
    };
  }, [isFullScreen]);

  const tableDetails = async () => {
    try {
      const fetchedTableData = await getCrewTableData(empArea, from, to);
      setTotalRec(
        fetchedTableData
          ? fetchedTableData?.length == undefined
            ? 0
            : fetchedTableData?.length
          : 0
      );
      setTableDataa(fetchedTableData ? fetchedTableData : []);
    } catch (error) {
      // notification.error({ message: "Error fetching zone data" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    tableDetails();
  }, [empArea, from, to]);

  const columns = [
    {
      title: "Asset ID",
      dataIndex: "id",
      // lakshayps
    },
    {
      title: "Asset Name",
      dataIndex: "assetName",
      render: (text) => {
        return <span className="text-center">{text}</span>;
      },
    },
    {
      title: "Asset Latitude",
      dataIndex: "lat",
      render: (text) => (
        <span className="d-flex justify-content-center w-50 text-center">
          {text}
        </span>
      ),
    },
    {
      title: "Asset Longitude",
      dataIndex: "long",
      render: (text) => (
        <span className="d-flex justify-content-center w-50 text-center">
          {text}
        </span>
      ),
    },
    {
      title: "Area",
      dataIndex: "area",
    },
    {
      title: "Date of Upload",
      dataIndex: "dateOfUpload",

      sorter: (a, b) => new Date(a.dateOfUpload) - new Date(b.dateOfUpload), //yash
    },
    {
      title: "Asset Health",
      dataIndex: "health",
      render: (text) => (
        <div className="dropdown action-label text-center w-100">
          <div
            // className="btn btn-white btn-sm btn-rounded"
            to="#"
            // data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "ACTION REQUIRED"
                  ? "far fa-dot-circle text-danger text-center"
                  : text === "OK" &&
                    "far fa-dot-circle text-success text-center"
              }
            />
            &nbsp;
            {text}
          </div>
        </div>
      ),
      sorter: (a, b) => a.health.length - b.health.length, //yash
    },
    {
      title: "Comment",
      dataIndex: "comment",
    },
  ];

  const tableData = tableDataa
    ? Object.values(tableDataa).map((data) => ({
        id: data?.id, //lakshayps
        assetName: data?.Asset_Name,
        lat: data?.Latitude == 0 ? "" : data?.Latitude,
        long: data?.Longitude == 0 ? "" : data?.Longitude,
        //lakshayps
        area: data?.Area === "Unknown Area" ? "" : data?.Area,
        dateOfUpload: data?.Date_of_Upload,
        health: data?.ASSET_STATUS,
        //lakshayps
        comment: data?.Comment === null ? "" : data?.Comment,
      }))
    : [];

  const placeholderData = new Array(5).fill({});
  const renderPlaceholder = () => (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-12" />
      </p>
      <p className="placeholder-wave mb-0">
        <span className="placeholder col-12" />
      </p>
    </>
  );

  return (
    <>
      <ToastContainer />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="page-title">Crew Report Data</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/import-data" style={{ color: "orange" }}>
                      Import Data
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Crew Report</li>
                </ul>
              </div>
              <div className="col-md-8 float-end ms-auto">
                <div className="d-flex title-head">
                  <div className="view-icons">
                    {/* <Link to="#" className="grid-view btn btn-link">
                      <i className="las la-redo-alt" />
                    </Link> */}
                    <Link
                      to="#"
                      className="list-view btn btn-link"
                      ref={maximizeBtnRef}
                    >
                      <i className="las la-expand-arrows-alt" />
                    </Link>
                    {/* <Link
                      to="#"
                      onClick={() => setFieldInputs(!fieldInputs)}
                      className={
                        fieldInputs
                          ? "list-view btn btn-link active-filter"
                          : "list-view btn btn-link"
                      }
                    >
                      <i className="las la-filter" />
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div
            className="filter-filelds"
            id="filter_inputs"
            style={{ display: fieldInputs ? "block" : "none" }}
          >
            <LeaveFilter
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              setEmpArea={setEmpArea}
              empArea={empArea}
              totalRec={totalRec}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  rowKey={(record) => record.id}
                  scroll={{ y: 300 }}
                  // columns={columns}
                  columns={
                    isLoading
                      ? columns.map((col) => ({
                          ...col,
                          render: () => renderPlaceholder(), // Render placeholder for all columns
                        }))
                      : columns
                  }
                  dataSource={
                    isLoading
                      ? placeholderData
                      : tableData.length > 0 // Check for records
                      ? tableData
                      : [] // No records case
                  }
                  pagination={{
                    current: currentPage,
                    pageSize: 10,
                    total: tableData?.length || 0, // Updated total
                    onChange: (page) => setCurrentPage(page),
                  }}
                  className="table-striped"
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrewReport;
