import React from "react";
import ImportData from "./ImportData";

const ImportDataView = () => {
  return (
    <>
      <ImportData />
    </>
  );
};

export default ImportDataView;
