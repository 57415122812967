import axios from "axios";
// import { useTable, notification } from "react-table";

const base_url = "https://predictive-maintenance.thesaicomputers.com/v1";

export function getTableData(empArea, from, to) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${base_url}/assets?Crew_record=${false}&NoAction_record=${false}&SaveLater_record=${false}&Area=${empArea}&Date_From=${from}&Date_To=${to}`
      )
      .then((response) => {
        // //console.log("response.data.newArr", response.data);
        resolve(response?.data);
      })
      .catch((error) => {
        // //console.log("errorerror", error);
        // notification.error(error);
        reject(error);
      });
  });
}

export function getCrewTableData(empArea, from, to) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${base_url}/assets?Crew_record=${true}&Area=${empArea}&Date_From=${from}&Date_To=${to}`
      )
      .then((response) => {
        // //console.log("response.data.newArr", response.data);
        // //console.log("responseresponse", response);
        resolve(response?.data);
      })
      .catch((error) => {
        // //console.log("errorerror", error);
        // notification.error(error);
        reject(error);
      });
  });
}
export function getSaveLaterTableData(empArea, from, to) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${base_url}/assets?SaveLater_record=${true}&Area=${empArea}&Date_From=${from}&Date_To=${to}`
      )
      .then((response) => {
        // //console.log("response.data.newArr", response.data);
        // //console.log("responseresponse", response);
        resolve(response?.data);
      })
      .catch((error) => {
        // //console.log("errorerror", error);
        // notification.error(error);
        reject(error);
      });
  });
}
export function getNoActionTableData(empArea, from, to) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${base_url}/assets?NoAction_record=${true}&Area=${empArea}&Date_From=${from}&Date_To=${to}`
      )
      .then((response) => {
        // //console.log("response.data.newArr", response.data);
        // //console.log("responseresponse", response);
        resolve(response?.data);
      })
      .catch((error) => {
        // //console.log("errorerror", error);
        // notification.error(error);
        reject(error);
      });
  });
}

export function getTableDataWithDate() {
  const dateFilter = localStorage.getItem("dateFilter");

  if (!dateFilter) {
    return Promise.reject(new Error("No date selected"));
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${base_url}/assets`, {
        params: {
          Date_of_Upload: dateFilter, // Pass Date_of_Upload as a query param
        },
      })
      .then((response) => {
        // //console.log("getTableDataWithDate response:", response);
        resolve(response?.data); // Resolve with the response data
      })
      .catch((error) => {
        // console.error("Error fetching table data by date:", error);
        reject(error); // Reject the promise with the error
      });
  });
}

export function getTableDataWithArea() {
  const filterArea = localStorage.getItem("filterArea");

  if (!filterArea) {
    return Promise.reject(new Error("No area selected"));
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${base_url}/assets`, {
        params: {
          Area: filterArea, // Pass Area as a query param
        },
      })
      .then((response) => {
        // //console.log("getTableDataWithArea response:", response);
        resolve(response?.data); // Resolve with the response data
      })
      .catch((error) => {
        // console.error("Error fetching table data for area:", error);
        reject(error); // Reject the promise with the error
      });
  });
}
export function updateRecord(data, id, report) {
  // //console.log("id:", id, "   datadatadatadatadata", data);
  // //console.log("commentcomment", comment);
  let crew_record = false;
  let noAction_record = false;
  let saveLater_record = false;
  let assetHealth = "";

  if (report == "crew") {
    crew_record = true;
    // assetHealth = "ACTION";
  } else if (report == "noAction") {
    noAction_record = true;
    // assetHealth = "OK";
  } else {
    saveLater_record = true;
  }

  let body = {
    IRON_DAMAGE: data?.iron_damage == "NO" ? false : true,
    IRON_POLE: data?.IRON_POLE == "NO" ? false : true,
    IRON_POLE_JUMPER: data?.iron_pole_jumper == "NO" ? false : true,
    PCC_CRACK: data?.pcc_crack == "NO" ? false : true,
    PCC_DAMAGE: data?.pcc_damage == "NO" ? false : true,
    RUST: data?.rust == "NO" ? false : true,
    VEGETATION: data?.vegetation == "NO" ? false : true,
    TILT: data?.tilt == "NO" ? false : true,
    PCC_POLE: data?.pcc_pole == "NO" ? false : true,
    PCC_POLE_HEAD: data?.pcc_pole_head == "NO" ? false : true,
    Crew_record: crew_record,
    NoAction_record: noAction_record,
    SaveLater_record: saveLater_record,
    // Comment: comment == ("" || null) ? "--" : comment,
  };

  // let finalBody={report=="crew"?Crew_record:true:}

  // //console.log("bodybodybodybodybodybody", body);

  return new Promise((res, rej) => {
    axios
      .post(`${base_url}/update/${id}`, body)
      .then((response) => {
        // //console.log("response", response);
        res(response.data); // Corrected `res` usage here
      })
      .catch((error) => {
        // console.error("error", error);
        rej(error); // Pass the error to the reject handler
      });
  });
}

export function updateAllOkCases() {
  // it takes empty body to work with all ok cases
  const body = {};
  return new Promise((res, rej) => {
    axios
      .post(`${base_url}/update_all_ok`, body)
      .then((response) => {
        // //console.log("resresresresres", res);
        res(response);
      })
      .catch((error) => {
        // //console.log("error", error);
        rej(error);
      });
  });
}

export function addComment(id, comment) {
  // //console.log("id:", id);
  // //console.log("comment:", comment);

  const body = {
    //lakshayps
    Comment: comment || "", // Default to "--" if comment is empty or null
  };

  // //console.log("Request body:", body);

  return new Promise((resolve, reject) => {
    axios
      .put(`${base_url}/assets/${id}/comment`, body)
      .then((response) => {
        // //console.log("Response of put apiiii:", response);
        resolve(response.data);
      })
      .catch((error) => {
        // console.error("Error:", error);
        reject(error);
      });
  });
}

export function getTableDataWithDate_Area() {
  const area = localStorage.getItem("filterArea");
  const date = localStorage.getItem("dateFilter");

  if (!area || !date) {
    return Promise.reject(new Error("Both date and area must be selected"));
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${base_url}/assets`, {
        params: {
          Date_of_Upload: date,
          Area: area, // Pass Date_of_Upload and Area as query params
        },
      })
      .then((response) => {
        // //console.log("getTableDataWithDate_Area response:", response);
        resolve(response?.data); // Resolve with the response data
      })
      .catch((error) => {
        // console.error("Error fetching table data by date and area:", error);
        reject(error); // Reject the promise with the error
      });
  });
}
