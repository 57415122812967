import { useEffect, useState } from "react";
import AllEmployee from "./component/allEmpCards";
import { getAllEmployeeData } from "../Services/ApiCalling";

const AllEmployeeView2 = () => {
  const [employeeData, setEmployeeData] = useState();

  const [loader, setloader] = useState(false);

  const [filter_id, setFilter_id] = useState("");
  const [filter_name, setFilter_name] = useState("");
  const [filter_desg, setFilter_desg] = useState("");

  const token = localStorage.getItem("LogIn_Token");

  const fetchEmployee = async (token, filter_id, filter_name, filter_desg) => {
    const resp = await getAllEmployeeData(
      token,
      filter_id,
      filter_name,
      filter_desg
    );
    setEmployeeData(resp.data);
    // //console.log("resp123", resp);

    //  //console.log("filter_id ", filter_id)
    //  //console.log("filter_name ", filter_name)
  };

  useEffect(() => {
    const tOut = setTimeout(() => {
      setloader(false);
      // //console.log('lll at fetch',loader)

      fetchEmployee(token, filter_id, filter_name, filter_desg);

      setloader(true);
      // //console.log('lll after fetch',loader)
    }, 600);
    return () => clearTimeout(tOut);
  }, [filter_id, filter_name, filter_desg]);

  return (
    <>
      {employeeData && employeeData.length > 0 && loader && (
        <AllEmployee
          employeeData={employeeData}
          setFilter_id={setFilter_id}
          setFilter_name={setFilter_name}
          setFilter_desg={setFilter_desg}
          filter_id={filter_id}
          filter_name={filter_name}
          filter_desg={filter_desg}
        />
      )}
    </>
  );
};

export default AllEmployeeView2;
