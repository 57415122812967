import React from "react";
// import Impo
import ImportDataView from "../PredictiveMaintainence/ImportData/index";
const PredictiveMaintainenceView = () => {
  return (
    <>
      <ImportDataView />
    </>
  );
};

export default PredictiveMaintainenceView;
