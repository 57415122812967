import React, { useState, useRef, useEffect } from "react";
import { Toast, ToastContainer, ProgressBar } from "react-bootstrap";
import { img_upload, s3UploadInput, s3UploadOutput } from "../Services/api";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

const ImportData = (props) => {
  const [filesData, setFilesData] = useState([]);
  const [loadImg, setLoadImg] = useState(false);
  const [showTopEndToast, setShowTopEndToast] = useState(false);
  const [toastVariant, setToastVariant] = useState("success");
  const [toastMessage, setToastMessage] = useState("");
  const [totalProgress, setTotalProgress] = useState(0);
  const [toggleUpload, setToggleUpload] = useState(false);
  const [toggleRemove, setToggleRemove] = useState(true);

  const navigate = useNavigate();
  const inputFileRef = useRef(null);

  const handleTopEndToastClose = () => {
    setShowTopEndToast(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!filesData || filesData.length === 0) {
      alert("Please select a file to upload.");
      return;
    }

    setLoadImg(true);
    setToggleUpload(true);
    setToggleRemove(false);

    try {
      // Attempt to upload all files
      const uploadSuccessful = await uploadAllFiles(filesData);

      if (uploadSuccessful) {
        setToastMessage("All images uploaded successfully!");
        setToastVariant("success");
      } else {
        setToastMessage(
          "All images uploaded successfully, but some images already exist."
        );
        setToastVariant("warning");
      }

      // Show the toast message
      setShowTopEndToast(true);

      // Simulate progress bar increment
      for (let index = 0; index <= 100; index += 10) {
        setTotalProgress(index);
      }

      // Navigate after a short delay if no loading indication
      if (!loadImg) {
        setTimeout(() => {
          navigate("/pending-report");
        }, 3000);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setToastMessage("Error uploading images. Please try again.");
      setToastVariant("danger");
      setShowTopEndToast(true);
      setToggleUpload(false);
      setToggleRemove(true);
    } finally {
      setLoadImg(false);
    }
  };

  const uploadAllFiles = async (files) => {
    try {
      const uploadDetails = await img_upload(files);

      const existingFiles = uploadDetails?.results?.filter(
        (obj) => obj["error"]
      );

      // If there are existing files, return `false`
      return existingFiles?.length === 0;
    } catch (error) {
      setToastVariant("danger");
      setToastMessage("Error while sending images to the server!");
      setShowTopEndToast(true);
      throw error;
    }
  };

  const handleFileChange = (e) => {
    const validImageTypes = ["image/jpeg", "image/jpg"];

    const newFiles = Array.from(e.target.files).filter((file) =>
      validImageTypes.includes(file.type)
    );

    if (newFiles.length !== e.target.files.length) {
      alert("Only image files (JPEG and JPG) are allowed.");
      return;
    }

    setFilesData((prevFiles) => [...prevFiles, ...newFiles]);
    setToggleRemove(true); // Show remove option
    setTotalProgress(0); // Reset progress for a new upload batch
  };

  const handleFileRemove = (indexToRemove) => {
    const updatedFiles = filesData.filter(
      (_, index) => index !== indexToRemove
    );
    setFilesData(updatedFiles);

    if (updatedFiles.length === 0 && inputFileRef.current) {
      inputFileRef.current.value = null;
    }
    setToggleUpload(false);
  };

  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content col-md-6 py-sm-5 container-fluid d-flex justify-content-center">
            <div className="row w-100 mt-5">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h1 className="card-title mb-0 text-center fs-3">
                      Import {props.title} Data
                    </h1>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="input-block mb-3">
                        {!toggleUpload ? (
                          <>
                            <label className="col-form-label">
                              <h5 className="fs-5">
                                Upload an image(s) - Only accepts jpg and jpeg
                                format
                              </h5>
                            </label>
                            <div className="upload-drop-zone w-100 d-flex pointer">
                              <i
                                className="fa fa-cloud-upload fa-2x carousel h-50"
                                style={{ left: "44%", top: "40%" }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                className="opacity-0 h-100 w-100 pointer"
                                onChange={handleFileChange}
                                multiple
                                ref={inputFileRef}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {filesData?.length > 0 ? (
                          <div
                            className=" overflow-y-scroll carousel"
                            style={{ height: "9rem" }}
                          >
                            {filesData?.map((file, index) => (
                              <div
                                className="d-flex justify-content-between my-1 align-items-baseline mx-2"
                                key={index}
                              >
                                <p>Selected file: {file.name}</p>
                                <ProgressBar
                                  now={totalProgress}
                                  label={`${totalProgress}%`}
                                  variant="success"
                                  className="mx-2"
                                  style={{ width: "30%" }}
                                />

                                {toggleRemove ? (
                                  <button
                                    type="button"
                                    className="btn btn-danger pointer"
                                    onClick={() => handleFileRemove(index)}
                                  >
                                    Remove File
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="btn-list d-flex justify-content-center">
                        <button
                          className="btn btn-primary pointer"
                          type="submit"
                          disabled={(loadImg || toggleUpload) && !toggleRemove}
                        >
                          {loadImg ? (
                            <>
                              <i className="fas fa-spinner fa-spin me-2" />
                              Uploading...
                            </>
                          ) : (
                            <>Upload</>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Container for positioning */}
      <ToastContainer className="p-3" position="top-end">
        <Toast
          show={showTopEndToast}
          onClose={handleTopEndToastClose}
          delay={3000}
          autohide
          bg={toastVariant}
        >
          <Toast.Header>
            <strong className="me-auto">
              {toastVariant == "success" ? "Success" : "Error"}
            </strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default ImportData;
