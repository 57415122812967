import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Row, Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
import LeaveFilter from "./LeaveFilter";
import ApplicationModal from "./application";
import { Tooltip } from "antd"; // Import Tooltip from Ant Design
import { InfoCircleOutlined } from "@ant-design/icons"; // Ant Design icon for "i"
import { getTableData, updateAllOkCases } from "../Services/apiCalling";
import { Area } from "recharts";

const PendingReport = () => {
  {
    /* this one start*/
  }
  const [fieldInputs, setFieldInputs] = useState(true);
  const [reqState, setReqState] = useState();
  const [clickedRecord, setClickedRecord] = useState([]);

  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);
  const [status, setStatus] = useState("Pending");
  const [remarks, setRemarks] = useState("");
  const [id, setId] = useState(null);
  const [noOfDays, setNoOfDays] = useState();
  const [toastContent, setToastContent] = useState();
  const [taskData, setTaskData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableDataaa, setTableDataaa] = useState([]);
  const [totalRec, setTotalRec] = useState(0);
  const [okCases, setOkCases] = useState([]);

  const date = new Date(); //yash
  let curr_date = date.toLocaleDateString(); //yash
  let first_date = new Date(date.getFullYear(), date.getMonth() - 1, 1); //yash

  ///convert date format into year-month-day start
  const optionsFrom = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  let localeDateFrom = new Date(first_date).toLocaleDateString(
    "en-GB",
    optionsFrom
  ); // format: dd/mm/yyyy
  const [day1, month1, year1] = localeDateFrom.split("/");
  const f = `${year1}-${month1}-${day1}`;

  const optionsTo = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  let localeDateTo = new Date(curr_date).toLocaleDateString("en-GB", optionsTo); // format: dd/mm/yyyy
  const [day2, month2, year2] = localeDateTo.split("/");
  const t = `${year2}-${month2}-${day2}`;
  ///convert date format into year-month-day end

  const [from, setFrom] = useState(f);
  const [to, setTo] = useState(t);
  const [empArea, setEmpArea] = useState("");

  const navigate = useNavigate();
  const token = localStorage.getItem("LogIn_Token");
  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        document.exitFullscreen && document.exitFullscreen();
        setFullScreen(false);
      }
    };

    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener("click", handleClick);

    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
    };
  }, [isFullScreen]);

  const tableDetails = async () => {
    try {
      const fetchedTableData = await getTableData(empArea, from, to);
      // //console.log("fetchedTableData", fetchedTableData);
      setTotalRec(
        fetchedTableData
          ? fetchedTableData?.length == undefined
            ? 0
            : fetchedTableData?.length
          : 0
      );
      setTableDataaa(fetchedTableData ? fetchedTableData : 0);
      // setTotalRec(fetchedTableData.length);
    } catch (error) {
      // notification.error({ message: "Error fetching zone data" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    tableDetails();
  }, [empArea, from, to]);

  const handleViewClick = async (record) => {
    setReqState(record);
    const popUp_record = tableDATA_boolean.filter((rec) => rec.id == record.id);
    setClickedRecord(popUp_record);
  };

  const columns = [
    {
      title: "Asset ID",
      dataIndex: "id",
    },
    {
      title: "Asset Name",
      dataIndex: "assetName",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Asset Latitude",
      dataIndex: "lat",
      render: (text) => (
        <span className="d-flex justify-content-center w-50">{text}</span>
      ),
    },
    {
      title: "Asset Longitude",
      dataIndex: "long",
      render: (text) => (
        <span className="d-flex justify-content-center w-50">{text}</span>
      ),
    },
    {
      title: "Area",
      dataIndex: "Area",
    },
    {
      title: "Date of Upload",
      dataIndex: "dateOfUpload",

      sorter: (a, b) => new Date(a.dateOfUpload) - new Date(b.dateOfUpload), //yash
    },
    {
      title: "Asset Health",
      dataIndex: "health",
      render: (text) => (
        <div className="dropdown action-label text-center w-100">
          <div to="#" aria-expanded="false">
            <i
              className={
                text === "ACTION REQUIRED"
                  ? "far fa-dot-circle text-danger text-center"
                  : text === "OK" &&
                    "far fa-dot-circle text-success text-center"
              }
            />
            &nbsp;
            {text}
          </div>
        </div>
      ),
      sorter: (a, b) => a.health.length - b.health.length, //yash
    },
    {
      title: "Detail",
      dataIndex: "detail",
      render: (text, record) => {
        //yash
        // return (
        //   <div className="dropdown dropdown-action d-flex justify-content-center w-50 text-center">
        //     <div>
        //       <Link
        //         to="#"
        //         // role="button"
        //         data-bs-toggle="modal"
        //         data-bs-backdrop="static"
        //         aria-labelledby="staticBackdropLabel"
        //         aria-hidden="true"
        //         data-bs-target="#edit_leave"
        //         onClick={() => {
        //           handleViewClick(record);
        //         }}
        //       >
        //         <i className=" m-r-5" /> &nbsp; VIEW
        //       </Link>
        //     </div>
        //   </div>
        // );
        // Only render VIEW link if there's a valid record
        return record && record.id ? (
          <div className="dropdown dropdown-action d-flex justify-content-center w-50 text-center">
            <div>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-backdrop="static"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                data-bs-target="#edit_leave"
                onClick={() => handleViewClick(record)}
              >
                <i className=" m-r-5" /> &nbsp; VIEW
              </Link>
            </div>
          </div>
        ) : null; // Render nothing if there's no record
      },
    },
  ];

  const tableDATA = tableDataaa
    ? Object.values(tableDataaa).map((data) => ({
        id: data?.id,
        assetName: data?.Asset_Name,
        lat: data?.Latitude == 0 ? "" : data?.Latitude,
        long: data?.Longitude == 0 ? "" : data?.Longitude,
        Area: data?.Area === "Unknown Area" ? "" : data?.Area,
        dateOfUpload: data?.Date_of_Upload,
        health: data?.ASSET_STATUS,
      }))
    : [];

  const tableDATA_boolean = tableDataaa
    ? Object.values(tableDataaa).map((data) => ({
        //yash
        id: data?.id,
        assetName: data?.Asset_Name,
        iron_pole: data?.IRON_POLE,
        pcc_pole: data?.PCC_POLE,
        iron_pole_jumper: data?.IRON_POLE_JUMPER,
        pcc_pole_head: data?.PCC_POLE_HEAD,
        vegetation: data?.VEGETATION,
        tilt: data?.TILT,
        pcc_crack: data?.PCC_CRACK,
        pcc_damage: data?.PCC_DAMAGE,
        rust: data?.RUST,
        iron_damage: data?.IRON_DAMAGE,
        iron_pole_jumper_damage: data?.IRON_POLE_JUMPER_DAMAGE,
        asset_status: data?.ASSET_STATUS,
        comment: data?.Comment,
        lat: data?.Latitude,
        long: data?.Longitude,
        //lakshayps
        Area: data?.Area == "Unknown Area" ? "" : data?.Area,
        dateOfUpload: data?.Date_of_Upload,
        health: data?.ASSET_STATUS,
        Crew_record: data?.Crew_record,
        NoAction_record: data?.NoAction_record,
        SaveLater_record: data?.SaveLater_record,
        Input_img_URL: data?.Input_img_URL,
        Output_img_URL: data?.Output_img_URL,
      }))
    : [];

  const handleAutoApprove = async () => {
    try {
      const fetchedDetails = await updateAllOkCases();
      await tableDetails();
      // //console.log("fetchedDetailsfetchedDetails", fetchedDetails);
    } catch (error) {
      alert(error);
      // //console.log("errorerrorerrorerror", error);
    }
    // navigate("/no-action-required-report", { state: okCases });
  };
  //lakshayps
  const placeholderData = new Array(5).fill({});
  const renderPlaceholder = () => (
    <>
      <p className="placeholder-glow">
        <span className="placeholder col-12" />
      </p>
      <p className="placeholder-wave mb-0">
        <span className="placeholder col-12" />
      </p>
    </>
  );

  return (
    <>
      {/* <submitToast/> */}
      <ToastContainer />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="page-title">Pending Report Data</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/import-data" style={{ color: "orange" }}>
                      Import Data
                    </Link>
                    {/*yash's change*/}
                  </li>
                  <li className="breadcrumb-item active">Pending Report</li>
                </ul>
              </div>
              <div className="col-md-8 float-end ms-auto">
                <div className="d-flex title-head">
                  <div className="view-icons">
                    {/*<Link to="#" className="grid-view btn btn-link">
                      <i className="las la-redo-alt" />
                    </Link>*/}
                    <Link
                      to="#"
                      className="list-view btn btn-link"
                      // id="collapse-header"
                      ref={maximizeBtnRef}
                    >
                      <i className="las la-expand-arrows-alt" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page Header */}
          <hr />

          <div
            className="filter-filelds"
            id="filter_inputs"
            style={{ display: fieldInputs ? "block" : "none" }}
          >
            <LeaveFilter
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              setEmpArea={setEmpArea} //yash
              empArea={empArea} //yash
              totalRec={totalRec}
            />
          </div>
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="table-responsive">
                {
                  <Table
                    rowKey={(record) => record.id}
                    scroll={{ y: 300 }}
                    columns={
                      isLoading
                        ? columns.map((col) => ({
                            ...col,
                            render: () => renderPlaceholder(), // Render placeholder while loading
                          }))
                        : columns
                    }
                    dataSource={
                      isLoading
                        ? placeholderData
                        : tableDATA.length > 0 // Check for records
                        ? tableDATA
                        : [] // No records case
                    }
                    pagination={{
                      current: currentPage,
                      pageSize: 10,
                      total: totalRec,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="table-striped"
                    loading={isLoading}
                  />
                }
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-info fs-5"
                  onClick={() => handleAutoApprove()}
                >
                  Auto Approve All OK Cases
                </button>

                {/* <div className="card-header relative"> */}
                <Tooltip
                  title=""
                  // placement="topRight"
                  className="d-flex justify-content-center align-items-center"
                  arrow={true}
                >
                  {" "}
                  <InfoCircleOutlined
                    title="It will approve & move all the records in No Action Report where asset health is OK"
                    className="block info-icon mx-3 fs-4"
                    style={{
                      color: "#1890ff",
                      cursor: "pointer",
                      width: "32px",
                    }}
                  />
                </Tooltip>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        {
          <ApplicationModal
            clickedRecord={clickedRecord ? clickedRecord : []}
          />
        }
      </div>
    </>
  );
};

export default PendingReport;
