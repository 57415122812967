import React, { useEffect, useState } from "react";
import { getEmployeeStatsData } from "../Services/ApiCalling";
import EmployeeLeave from "./component/EmployeeLeave";

function EmpStatsView() {
  const [empStatsData, setEmpStatsData] = useState();

  const token = localStorage.getItem("LogIn_Token");

  let EMP = localStorage.getItem("DecodedToken");
  EMP = JSON.parse(EMP);

  const StatsData = async (EMP) => {
    const res = await getEmployeeStatsData(EMP?.EMP_ID);
    setEmpStatsData(res.data);
    // //console.log('resonse stats', res.data)
  };
  useEffect(() => {
    StatsData(EMP);
  }, []);
  return <>{empStatsData && <EmployeeLeave empStatsData={empStatsData} />}</>;
}

export default EmpStatsView;
