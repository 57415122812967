import Axios from "axios";
const base_Url6 = "https://predictive-maintenance.thesaicomputers.com/v1";

// const base_Url6 = "http://162.147.132.171:6379";

export function img_upload(obj_body) {
  const formData = new FormData();

  for (let index = 0; index < obj_body.length; index++) {
    formData.append("files", obj_body[index]);
  }
  let body = {
    files: formData,
  };

  return new Promise((res, rej) => {
    Axios.post(`${base_Url6}/upload/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log("responseresponse", response);
        return res(response.data);
      })
      .catch((error) => {
        alert(error);
        rej(error);
      });
  });
}

// export function img_upload(obj_body) {
//   //console.log("Original obj_body length:", obj_body.length);
//   //console.log("Original obj_body:", obj_body);

//   // Transform obj_body (array of arrays) into an object of objects
//   const filesObject = {};
//   obj_body.forEach((item, index) => {
//     filesObject[`${index}`] = item;
//   });

//   //console.log("filesObjectfilesObject", filesObject);

//   let body = {
//     files: filesObject,
//   };

//   //console.log("Transformed body:", body);

//   return new Promise((res, rej) => {
//     Axios.post(`${base_Url6}/upload/`, body[0], {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//       .then((response) => {
//         //console.log("Response received:", response);
//         res(response.data);
//       })
//       .catch((error) => {
//         //console.log("Error:", error);
//         rej(error);
//       });
//   });
// }

export function s3UploadInput(obj_body) {
  let body = {
    files: obj_body,
  };

  const formData = new FormData();
  for (let index = 0; index < obj_body.length; index++) {
    formData.append("files", obj_body[index]);
  }
  return new Promise((res, rej) => {
    Axios.post(
      `https://idip.thesaicomputers.com/v1/upload-to-s3bucket/input/predective-maintenance-1729788871050`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        // //console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        rej(error);

        // //console.log("errror");
      });
  });
}

export function s3UploadOutput(obj_body) {
  // let body = {
  //   files: obj_body,
  // };

  const formData = new FormData();

  for (let index = 0; index < obj_body.length; index++) {
    formData.append("files", obj_body[index]);
  }

  return new Promise((res, rej) => {
    Axios.post(
      `https://idip.thesaicomputers.com/v1/upload-to-s3bucket/output/predective-maintenance-1729788871050`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        // //console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
        // //console.log("errror");
      });
  });
}
