import { useDispatch, useSelector } from "react-redux";
import { login } from "../Services/AuthServices";
import { useNavigate } from "react-router-dom";
import {
  OnSignIn,
  onSignOut,
  // setToken,
  setUserAreaDet,
  setUserDet,
} from "../../store/slices/authSlice";
import { jwtDecode } from "jwt-decode";
// import { getUserAreaDeatils } from "../Services/ApiCalling";

function useAuth() {
  // //console.log("In useAth");
  const state = useSelector((state) => state);
  // //console.log(state);

  // const token = useSelector((state) => state.auth.token);

  const token = localStorage.getItem("LogIn_Token");

  // //console.log("tokenn", token);
  // const isSignedIn = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  // //console.log("OnSignInOnSignIn",OnSignIn)
  const signIn = async (email, password) => {
    const response = await login(email, password);
    if (response.Success) {
      // dispatch(OnSignIn(response.Body));
      if (response.Body) {
        // let areaDet = await getUserAreaDeatils(response.Body);
        // //console.log("areaDetareaDetareaDetareaDetareaDet", areaDet);
        // dispatch(setUserAreaDet(areaDet.data));
        localStorage.setItem("LogIn_Token", response.Body);
      }

      let decodedToken2 = jwtDecode(response.Body);
      localStorage.setItem("DecodedToken", JSON.stringify(decodedToken2));

      // const decodedToken = jwtDecode(response.Body);

      // let currTime = new Date().getTime();
      // currTime = currTime + 7200000;
      // localStorage.setItem("tokenExpTime", currTime.toString());

      // dispatch(setUserDet(decodedToken));
      return response;
    } else if (response.Error) {
      // //console.log("else condition");
      return response;
    }
  };

  const signOut = () => {
    if (token) {
      localStorage.removeItem("tokenExpTime");

      localStorage.removeItem("LogIn_Token");

      localStorage.removeItem("DecodedToken");
      // dispatch(onSignOut());
    }
  };

  return { token, signIn, signOut };
  // return { token, signIn, signOut, isSignedIn };
}

export default useAuth;
