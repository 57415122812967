// import React, { useState, useEffect } from "react";
// import Select from "react-select";
// import DatePicker from "react-datepicker";
// import { Link, useNavigate } from "react-router-dom";
// import { Tooltip } from "antd"; // Import Tooltip from Ant Design
// import { InfoCircleOutlined } from "@ant-design/icons"; // Ant Design icon for "i"

// const LeaveFilter = ({
//   setEmpArea,
//   empArea,
//   from,
//   to,
//   totalRec,
//   setFrom,
//   setTo,
// }) => {
//   const navigate = useNavigate();

//   const [dateOne, setdateOne] = useState(true);
//   const [dateTwo, setdateTwo] = useState(true);
//   const minDate2 = new Date();
//   const [itemFocus, setItemFocus] = useState(true);

//   const inputFocus = () => {
//     setItemFocus(true);
//   };

//   const inputBlur = () => {
//     setItemFocus(true);
//   };

//   const handleFocus = () => {
//     setdateOne(true);
//   };

//   const handleBlur = () => {
//     setdateOne(true);
//   };

//   const handleFocustwo = () => {
//     setdateTwo(true);
//   };
//   const handleBlurtwo = () => {
//     setdateTwo(true);
//   };

//   const handleDateChange = (date) => {
//     const options = {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//     };
//     let localeDate = new Date(date).toLocaleDateString("en-GB", options); // format: dd/mm/yyyy
//     const [day, month, year] = localeDate.split("/");
//     if (to && date > new Date(to)) {
//       alert("From Date cannot be after To Date");
//     } else {
//       setFrom(`${year}-${month}-${day}`);
//     }
//   };

//   const handleDateChangeTwo = (date) => {
//     const options = {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//     };
//     let localeDate = new Date(date).toLocaleDateString("en-GB", options); // format: dd/mm/yyyy
//     const [day, month, year] = localeDate.split("/");
//     if (from && new Date(from) > date) {
//       alert("To Date cannot be before From Date");
//     } else {
//       setTo(`${year}-${month}-${day}`);
//     }
//   };

//   const handleInputChange = (e) => {
//     setEmpArea(e.target.value);
//   };

//   const leavestatus = [
//     { value: "Tripura", label: "Tripura" },
//     { value: "Meghalaya", label: "Meghalaya" },
//   ];

//   const customStyles = {
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
//       color: state.isFocused ? "#fff" : "#000",
//       "&:hover": {
//         backgroundColor: "#ff9b44",
//       },
//     }),
//   };

//   return (
//     <div>
//       {/* Search Filter */}
//       <div className="row filter-row">
//         <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
//           <div
//             className={`input-block mb-3 form-focus ${
//               itemFocus ? "focused" : ""
//             } `}
//           >
//             <input
//               type="text"
//               className="form-control floating"
//               value={totalRec}
//               disabled={true}
//             />
//             <label className="focus-label">Total Record</label>{" "}
//             {/*yash's change*/}
//           </div>
//         </div>
//         <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
//           <div
//             className={`input-block mb-3 form-focus ${
//               itemFocus ? "focused" : ""
//             } `}
//           >
//             <input
//               type="text"
//               className="form-control floating"
//               onFocus={inputFocus}
//               onBlur={inputBlur}
//               onChange={handleInputChange}
//               value={empArea}
//               placeholder="Type area to be searched"
//             />
//             <label className="focus-label">Area</label> {/*yash's change*/}
//           </div>
//         </div>

//         <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
//           <div
//             className={`input-block mb-3 form-focus ${
//               dateOne ? "focused" : ""
//             }`}
//           >
//             <div className="cal-icon">
//               <DatePicker
//                 className="form-control floating datetimepicker"
//                 selected={from}
//                 onChange={handleDateChange}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//                 dateFormat="yyyy-MM-dd"
//                 maxDate={minDate2}
//               />
//             </div>
//             <label className="focus-label">From Date</label>
//           </div>
//         </div>

//         <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
//           <div
//             className={`input-block mb-3 form-focus ${
//               dateTwo ? "focused" : ""
//             }`}
//           >
//             <div className="cal-icon">
//               <DatePicker
//                 className="form-control floating datetimepicker"
//                 selected={to}
//                 onChange={handleDateChangeTwo}
//                 onFocus={handleFocustwo}
//                 onBlur={handleBlurtwo}
//                 dateFormat="yyyy-MM-dd"
//                 maxDate={to}
//               />
//             </div>
//             <label className="focus-label">To Date</label>
//           </div>
//         </div>
//       </div>
//       {/* /Search Filter */}
//     </div>
//   );
// };

// export default LeaveFilter;

import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Tooltip } from "antd"; // Import Tooltip from Ant Design
import { InfoCircleOutlined } from "@ant-design/icons"; // Ant Design icon for "i"

const LeaveFilter = ({
  setEmpArea,
  empArea,
  from,
  to,
  totalRec,
  setFrom,
  setTo,
}) => {
  const [dateOne, setDateOne] = useState(true);
  const [dateTwo, setDateTwo] = useState(true);
  const minDate = new Date();
  const [itemFocus, setItemFocus] = useState(true);

  const inputFocus = () => setItemFocus(true);
  const inputBlur = () => setItemFocus(true);

  const handleFocus = () => setDateOne(true);
  const handleBlur = () => setDateOne(true);
  const handleFocusTwo = () => setDateTwo(true);
  const handleBlurTwo = () => setDateTwo(true);

  // const handleDateChange = (date) => {
  //   const formattedDate = date.toISOString().split("T")[0]; // Format yyyy-MM-dd
  //   console.log("fromfrom", from);
  //   setFrom(formattedDate);
  // }

  const handleDateChange = (date) => {
    if (to && date > new Date(to)) {
      alert("From Date cannot be after To Date");
    } else {
      const formattedDate = date.toISOString().split("T")[0]; // Format yyyy-MM-dd
      setFrom(formattedDate);
    }
  };

  // const handleDateChangeTwo = (date) => {
  //   const formattedDate = date.toISOString().split("T")[0];
  //   console.log("toto", to);
  //   setTo(formattedDate);
  // };

  const handleDateChangeTwo = (date) => {
    if (from && new Date(from) > date) {
      alert("To Date cannot be before From Date");
    } else {
      const formattedDate = date.toISOString().split("T")[0];
      setTo(formattedDate);
    }
  };
  const handleInputChange = (e) => setEmpArea(e.target.value);

  const leavestatus = [
    { value: "Tripura", label: "Tripura" },
    { value: "Meghalaya", label: "Meghalaya" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      className="form-control floating datetimepicker"
      onClick={onClick}
      value={value}
      readOnly // Prevents typing but allows date selection
    />
  );

  return (
    <div>
      {/* Search Filter */}
      <div className="row filter-row">
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              itemFocus ? "focused" : ""
            }`}
          >
            <input
              type="text"
              className="form-control floating"
              value={totalRec}
              disabled={true}
            />
            <label className="focus-label">Total Record</label>
          </div>
        </div>

        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              itemFocus ? "focused" : ""
            }`}
          >
            <input
              type="text"
              className="form-control floating"
              onFocus={inputFocus}
              onBlur={inputBlur}
              onChange={handleInputChange}
              value={empArea}
              placeholder="Type area to be searched"
            />
            <label className="focus-label">Area</label>
          </div>
        </div>

        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              dateOne ? "focused" : ""
            }`}
          >
            <div className="cal-icon">
              <DatePicker
                selected={from}
                onChange={handleDateChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                dateFormat="yyyy-MM-dd"
                maxDate={minDate}
                customInput={<CustomInput />}
              />
            </div>
            <label className="focus-label">From Date</label>
          </div>
        </div>

        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-12">
          <div
            className={`input-block mb-3 form-focus ${
              dateTwo ? "focused" : ""
            }`}
          >
            <div className="cal-icon">
              <DatePicker
                selected={to}
                onChange={handleDateChangeTwo}
                onFocus={handleFocusTwo}
                onBlur={handleBlurTwo}
                dateFormat="yyyy-MM-dd"
                maxDate={minDate}
                customInput={<CustomInput />}
              />
            </div>
            <label className="focus-label">To Date</label>
          </div>
        </div>
      </div>
      {/* /Search Filter */}
    </div>
  );
};

export default LeaveFilter;
